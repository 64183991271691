const ASSETS = {
  kopvillkor:
    'https://jarvaveckan.se/wp-content/uploads/2024/11/Kopvillkor-integritetspolicy-och-GDPR-Stiftelsen-Jarvaveckan-v2.0.pdf',
  kort_om_Jarvaveckan_2023:
    'https://firebasestorage.googleapis.com/v0/b/jarvaveckan-live.appspot.com/o/assets%2FOm%20Ja%CC%88rvaveckan%202023%20230119_compressed.pdf?alt=media&token=0a862054-7364-47bc-8d87-8bad1766234f',
  inredningspaket:
    'https://firebasestorage.googleapis.com/v0/b/jarvaveckan-live.appspot.com/o/assets%2FInredningspaket%20Ja%CC%88rvaveckan%202023_compressed.pdf?alt=media&token=d70631ff-3fcc-45bc-b682-b065400cb175',
  work_man_info:
    'https://firebasestorage.googleapis.com/v0/b/jarvaveckan-live.appspot.com/o/assets%2Fwork_man_info.pdf?alt=media&token=afcaf63c-3ab5-4365-9b1e-8f02970af01a',
  riktlinjer:
    'https://firebasestorage.googleapis.com/v0/b/jarvaveckan-live.appspot.com/o/assets%2FRiktlinjer%20JA%CC%83%C2%A4rvaveckan%202023%20v1.1_compressed.pdf?alt=media&token=7d2b287d-6585-42d6-b705-c563da43e2a0',
  varderingarUppforandekod:
    'https://jarvaveckan.se/wp-content/uploads/2024/11/Vardegrund-och-uppforandekod-Stiftelsen-Jarvaveckan-v2.0.pdf',
};

export { ASSETS };
