/**
 *
 * Login
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Button, Form, Message, Modal } from 'semantic-ui-react';

import { StyledFormContainer, Wrapper } from '../../styledComponents';
import { errorCallback } from '../../utils/index';
import MyFallbackComponent from '../MyFallbackComponent';
import TermsLinks from '../TermsLinks';

class Login extends Component {
  state = {
    email: '',
    password: '',
    isClicked: false,
    loginAuthError: false,
    modalOpen: false,
    signInLoading: false
  };

  handleOpen = (e) => {
    e.preventDefault();
    this.setState({ modalOpen: true, emailSuccess: false, emailError: false });
  };

  handleClose = () => this.setState({ modalOpen: false });

  handleInputChange = (_, { name, value }) => {
    this.setState({ [name]: value });
    const { isClicked } = this.state;
    if (isClicked) this.setState({ isClicked: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    if (email === '' && password === '') {
      return;
    }
    this.setState({ isClicked: true, signInLoading: true });
    const { firebase, history, auth, toPath } = this.props;
    if (auth.isLoaded && auth.isEmpty) {
      firebase
        .login({
          email,
          password
        })
        .then(() => {
          history.push(toPath);
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            isClicked: false,
            signInLoading: false,
            loginAuthError: err
          });
        });
    }
  };

  resetPassword = () => {
    const { firebase } = this.props;
    firebase.auth().languageCode = 'se';
    const auth = firebase.auth();
    const { email } = this.state;
    this.setState({ resetPasswordLoading: true });
    if (email !== '') {
      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          this.setState({ resetPasswordLoading: false, emailSuccess: true });
          window.localStorage.setItem('email', email);
        })
        .catch((error) => {
          this.setState({
            resetPasswordLoading: false,
            emailError: { ...error }
          });
        });
    }
  };

  render() {
    const { goToSignUp, admin } = this.props;
    const {
      email,
      password,
      isClicked,
      loginAuthError,
      modalOpen,
      resetPasswordLoading,
      signInLoading,
      emailSuccess,
      emailError
    } = this.state;
    return (
      <Wrapper>
        <StyledFormContainer>
          <h3>Logga in</h3>
          <p>
            Ny på Järvaveckans bokningssida?{' '}
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                goToSignUp();
              }}
            >
              Skapa ett nytt konto
            </a>
          </p>
          <br />
          <br />
          <Form
            loading={signInLoading}
            onSubmit={this.handleSubmit}
            error={Boolean(loginAuthError)}
          >
            <Form.Input
              maxLength="120"
              type="email"
              onChange={this.handleInputChange}
              required
              id="loginOrgNr"
              name="email"
              placeholder="Email"
              label="E-post"
              value={email}
            />
            <Form.Input
              maxLength="40"
              onChange={this.handleInputChange}
              value={password}
              required
              label="Lösenord"
              type="password"
              id="loginPassword"
              name="password"
              autoComplete="on"
              placeholder="Password"
            />
            <Message
              error
              header={loginAuthError && loginAuthError.code}
              content={loginAuthError && loginAuthError.message}
            />
            <Button type="submit" fluid primary disabled={isClicked}>
              Logga in
            </Button>
            {admin ? (
              ''
            ) : (
              <>
                <br />
                <br />
                <a onClick={this.handleOpen} href="#reset-password">
                  Har du glömt ditt lösenord?
                </a>
                <br />
                <p>
                  Alla ansökningar genomgår en granskning innan de godkänns för
                  ett konto hos Järvaveckan.
                </p>
                <TermsLinks />
              </>
            )}
          </Form>
          <Modal
            open={modalOpen}
            onClose={this.handleClose}
            size="tiny"
            closeOnDimmerClick
            dimmer="inverted"
          >
            <Modal.Content>
              <Form
                onSubmit={this.resetPassword}
                loading={resetPasswordLoading}
              >
                <Form.Input
                  maxLength="120"
                  type="email"
                  onChange={this.handleInputChange}
                  required
                  id="loginOrgNr"
                  name="email"
                  placeholder="Email"
                  label="Skriv in e-postadressen kopplad till ditt konto:"
                  value={email}
                />
                {emailSuccess ? (
                  <>
                    <Message
                      positive
                      content="Ett e-postmeddelande har skickats med instruktioner om hur du återställer ditt lösenord."
                    />
                    <Button
                      primary
                      basic
                      icon="cancel"
                      labelPosition="right"
                      content="Stäng"
                      type="button"
                      floated="right"
                      onClick={this.handleClose}
                    />
                  </>
                ) : (
                  <>
                    {emailError && (
                      <Message
                        negative
                        header="Det finns inget konto som använder den e-postadressen."
                        list={[emailError.code, emailError.message]}
                      />
                    )}
                    <Button
                      secondary
                      basic
                      icon="cancel"
                      labelPosition="right"
                      content="Avbryt"
                      type="button"
                      onClick={this.handleClose}
                    />
                    <Button
                      primary
                      icon="mail"
                      labelPosition="right"
                      content="Skicka"
                      type="submit"
                      floated="right"
                    />
                  </>
                )}
              </Form>
            </Modal.Content>
          </Modal>
        </StyledFormContainer>
      </Wrapper>
    );
  }
}

Login.propTypes = {
  firebase: PropTypes.object,
  history: PropTypes.object,
  auth: PropTypes.object,
  toPath: PropTypes.string.isRequired,
  admin: PropTypes.bool,
  goToSignUp: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  auth: (state) => state.firebase.auth,
  profile: (state) => state.firebase.profile
});

const withConnect = connect(mapStateToProps);

export default compose(
  withRouter,
  withFirestore,
  withConnect
)(withErrorBoundary(Login, MyFallbackComponent, errorCallback));
