/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';

import ScrollToTop from './components/ScrollToTop/index';
import configureStore from './configureStore';
import App from './containers/App';
import GlobalStyle from './global-styles';
import SemanticStyles from './semantic-import';

import 'sanitize.css';
import Offline from './components/Offline';

const initialState = {};
const store = configureStore(initialState);

const MOUNT_NODE = document.getElementById('app');
const root = createRoot(MOUNT_NODE);

function AppWithState() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <StyleSheetManager shouldForwardProp={isPropValid}>
          <ScrollToTop>
            <App />
            <SemanticStyles />
            <GlobalStyle />
            <Offline />
          </ScrollToTop>
        </StyleSheetManager>
      </BrowserRouter>
    </Provider>
  );
}

root.render(<AppWithState />);
